<template>
  <div class="abonentsList">
    <div class="card" >
      <div class="card-header d-flex justify-content-start align-items-center">
        <div class="col-sm-3" v-if="me.roles[0].name == 'admin' || me.roles[0].name == 'moderator'">
          <label for="">Фильтрация по статусам</label>
          <a-select
              mode="multiple"
              placeholder="Выберите статуса"
              v-model="dataFilter.status"
              optionLabelProp="label"
              optionFilterProp="label"
              style="width: 100%"
              @change="applyFilter"
            >
            <a-select-option v-for="status in statuses" :key="status.value" :value="status.value" :label="status.title">
              {{status.title}}
            </a-select-option>
          </a-select>
        </div>
        <!-- <div class="col-sm-3">
          <label for="">Фильтрация по группам</label>
          <a-select
              mode="multiple"
              placeholder="Выберите группу"
              v-model="dataFilter.group_id"
              optionLabelProp="label"
              optionFilterProp="label"
              style="width: 100%"
              @change="applyFilter"
            >
            <a-select-option v-for="group in groups" :key="group.id" :value="group.id" :label="group.title">
              {{group.title}} <span v-if="group.abonents_count" style="color: blue"> {{group.abonents_count}} - абонентов</span>
            </a-select-option>
          </a-select>
        </div> -->
      </div>
    </div>
    <mini-loader v-if="load" />
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Абоненты рассылки</h5>

      </div>
      <div class="table-with-scroll">
        <table class="table table-striped" id="abonentsList">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Лист</th>
              <th scope="col">Группа</th>
              <th scope="col">Email</th>
              <th scope="col">Создатель</th>
              <th scope="col">Статус</th>
              <th scope="col">Время обновления</th>
              <th scope="col">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in data">
              <th scope="row">{{index+1}}</th>
              <td>{{value.list ? value.list.title : '--'}}</td>
              <td v-if="value.group">
                <button class="btn btn-secondary m-1">{{value.group.title}}</button>
              </td> <td v-else>--</td>
              <td> {{value.email}} </td>
              <td v-if="value.creator">{{value.creator.name}}</td> <td v-else>--</td>
              <td>
                <button class="btn btn-info m-1" v-if="value.status == 'pending'">В ожидании</button>
                <button class="btn btn-success m-1" v-if="value.status == 'success'">Успешно</button>
                <button class="btn btn-danger m-1" v-if="value.status == 'error'">Неудачно</button>
              </td>
              <td>{{createdAt(value.updated_at)}}</td>
              <td style="min-width:150px">
                <a  @click="showItem(value)">
                  <span class="border rounded border-primary m-1 p-2 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                    </svg>
                  </span>
                </a>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
import moment, { Moment } from 'moment';

const data = '';
export default {
  data() {
    return {

      statuses: [
        {
          title: "В ожидании",
          value: "pending"
        },
        {
          title: "Успешно",
          value: "success"
        },
        {
          title: "Неудачно",
          value: "error"
        },
      ],

      dataFilter: {
        status: [],
        group_id: [],
        page: 1,
      },

      ind: -10,
      load: true,
      visible: false,
      visibleImportFromExcellModal: false,
      visibleCreateModal: false,
      visibleEditModal: false,
      visibleDeleteModal: false,

      item: {
        title: '',
        description: '',
        group_id: []
      },
      newItem: {
        title: '',
        description: '',
        group_id: []
      },

      data,
      users: [],
      groups: [],
      allGroups: [],
      myGroups: [],

      currentPage: 1,
      totalResults: 0,
      old_height: 0,
      last_page: 1,
      load: true,
      validateErrors: []

    }
  },
  mounted(){
    this.load = true;
    if(this.me.roles[0].name == 'admin' || this.me.roles[0].name == 'moderator'){
      this.getUsers();
    }
    this.getGroups();
    this.getMailingAbonents();

    window.onscroll = () => {
        let height = document.getElementById('abonentsList').clientHeight;
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            this.dataFilter.page = this.data.length/this.$store.state.paginateCount + 1;
            if(this.dataFilter.page <= this.last_page){
              this.getMailingAbonentsPaginate();
            }
          }
        }
    };

  },
  methods: {

    storeItem(){
      this.load = true;
      axios.post('/employee/mailing/email/mailing', this.newItem).then((response)  =>  {
        if(response.status == 201){
          this.visibleCreateModal = false;
          this.data = this.data.concat(response.data)
          this.newItem.title = '';
          this.newItem.description = '';
          this.newItem.group_id = [];
          this.totalResults++;

          console.log(response.data);

          this.$message.success(
            'Успешно добавлено',
            3
          );
        }
      }).catch((error)  =>  {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors;
          this.$message.error(
            error.response.data.message,
            3
          );
        }
      }).finally(() =>  {this.load = false;});
    },
    updateItem(){
      this.load = true;
      axios.put('/employee/mailing/email/abonents/'+this.item.id, this.item).then((response)  =>  {
        console.log(response);
        if(response.status == 200){
          this.$message.success(
            'Успешно сохранено',
            3
          );
          this.visibleEditModal = false;
        }
      }).catch((error)  =>  {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors;
          this.$message.error(
            error.response.data.message,
            3
          );
        }else if(error.response.status == 404 || error.response.status == 403){
          this.$message.error(
            error.response.data.error,
            3
          );
        }
      }).finally(() => {this.load = false;})
    },
    destroyItem(){
      // this.load = true;
      this.$message.error(
        "Невозможно удалить",
        3
      )
      // axios.delete('/employee/mailing/email/abonents/'+this.item.id).then((response)  =>  {
      //   if(response.status == 200){
      //     this.$message.success(
      //           response.data.success,
      //           3,
      //     );
      //     this.data.splice(this.ind, 1)
      //     this.totalResults--;
      //     this.visibleDeleteModal = false
      //   }
      // }).catch((error)  =>  {
      //   if(error.response.status == 404 || error.response.status == 403){
      //     this.$message.error(
      //       error.response.data.error,
      //       3
      //     );
      //   }else if(error.response.status == 500){
      //     this.$message.error(
      //       'Ошибка сервера',
      //       3
      //     );
      //   }
      // }).finally(() =>  {this.load = false;})
    },

    showItem(value){

    },
    createItem(){
      this.visibleCreateModal = true;
    },
    editItem(value){
      this.item = value;
      this.validateErrors = [];
      // this.visibleEditModal = true;
    },
    deleteItem(index, value){
      this.item = value;
      this.ind = index;
      this.visibleDeleteModal = true;
    },
    applyFilter(){
      this.getMailingAbonents();
    },

    getMailingAbonents(){
      this.load = true;
      axios.get('/employee/mailing/email/mailing/' + this.$route.params.id, {params: this.dataFilter}).then((response) =>  {
        if(response.status == 200){
          console.log(response.data.data);
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.$message.error(
            'Рассылки не найдены',
            3
          );
        }
      }).catch((error)  =>  {

      }).finally(() =>  {this.load = false;});
    },

    getGroups(){
      axios.get('/employee/mailing/email/groups-all').then((response) =>  {
        if(response.status == 200){
          this.allGroups = response.data;
          console.log(this.allGroups);
          this.groups    = response.data;
          this.myGroups  = this.allGroups.filter((el) => this.me.id === el.creator_id);
        }else if(response.status == 204){
          this.$message.error(
            'Группы не найдены',
            3
          );
        }
      }).catch((error)  =>  {

      }).finally(() =>  {});
    },
    getUsers(){
      axios.get('/users/admin-moder-oper/get').then((response) => {
        if(response.status == 200){
          this.users = response.data;
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {})
    },

    getMailingAbonentsPaginate(){
      this.load = true;
      axios.get('/employee/mailing/email/mailing/' + this.$route.params.id, {params: this.dataFilter}).then((response) =>  {
        if(response.status == 200){
          this.data = this.data.concat(response.data.data);
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.$message.error(
            'Рассылки не найдены',
            3
          );
        }
      }).catch((error)  =>  {

      }).finally(() =>  {this.load = false;});
    },

    createdAt(date){
      let d = new Date(date).toLocaleDateString();
      return moment(date).format('DD/MM/YYYY HH:MM');
    },
  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    },
  },

}
</script>

<style>
  .card .table-with-scroll{
      overflow-x: auto;
      max-width: 100%
  }
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
</style>
